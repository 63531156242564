const getters = {
  token: state => state.user.token,
  role: state => state.user.role,
  name: state => state.user.user.name,
  user: state => state.user,
  pusher_response: state => state.pusher.pusher_response,
  pusher_success: state => state.pusher. pusher_response?.success,
}

export default getters
