const state = {
    contectUsPopUp: false
}
const mutations = {
    CHANGE_CONTECT_US_POP_UP: (state, status) => {
        state.contectUsPopUp = status
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    // actions
  };