
const getDefaultState = () => {
  return {
    pusher_response: "erthtrhtrh"
  };
};

const state = getDefaultState();

const mutations = {
  SET_PUSHER_RESPONSE: (state, response) => {
    console.log('state -----------', state)
    console.log('SET_PUSHER_RESPONSE -----------', response);
    state.pusher_response = response;
    console.log('state.pusher_response --------', state.pusher_response);
  },
  RESET_PUSHER_RESPONSE: (state) => {
    console.log('state -----------', state)
    state.pusher_response = null;
    console.log('state.pusher_response --------', state.pusher_response);
  },
};

export default {
  namespaced: true,
  state,
  mutations
};
